import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import Services from "../pages/Services";
import Gallery from "../pages/Gallery";
import Admin from "../pages/Admin";
import cms from '../cms/CMS.json';

function MainRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Home cmsData={cms} />} />
            <Route path="/admin" element={<Admin cmsData={cms}/>} />   
        </Routes>
        );
}

export default MainRoutes;
import { Container } from 'react-bootstrap';
import Banner from '../components/Banner';
import AboutUs from '../components/AboutUs';
import Contact from '../components/Contact';
import Album from '../components/Album';

function Home(props) {

  const getCMSData=(key)=>{
    return props && props.cmsData && props.cmsData.length >0 && props.cmsData.filter((flt)=> flt.key == key)[0];
  }

  return (<section id="inner-headline">
    <Container>
      <Banner />     
      {/* <Products  /> */}
      {/* <Blog /> */}
      <Album />
      <AboutUs />
      <Contact {...getCMSData("contact")} />
    </Container>
  </section>)
}
export default Home;

import { Col, Container, Row } from "react-bootstrap";

function Contact(props) {

    const renderContents = () => {
        return props && props.content && props.content.length > 0 && props.content.map((item) => {
            return <Col md={4}>
                <div className="text-center">
                    <i className={item.logo}></i>
                    <h3>{item.name}</h3>
                    {renderText(item)}
                </div>
            </Col>
        })
    }

    const renderText = (item) => {
        if (item.link) {
            return <a href={item.link} target="_blank">{item.value}</a>
        }
        else {
            return <p>{item.value}</p>
        }
    }

    return (<section id="contact">
        <Container>
            <div>
                <h3 className="text-primary my-3 py-5 text-center">{props.displayName}</h3>
                <p>{props.description}</p>
                <Row>
                    {
                        renderContents()
                    }
                </Row>
            </div>
        </Container>
    </section>);
}

export default Contact;
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Carousel from 'react-bootstrap/Carousel';

function Banner() {

    // const [isDisplayVideo, setIsDisplayVideo] = useState(false);

    // useEffect(() => {
    //     setIsDisplayVideo(true);
    //   }, []);


    return (
        <Row className="justify-content-center align-items-center">
            <Col lg={12}>
                <Carousel fade>
                    <Carousel.Item>
                        <img className="d-block w-100" src={require("../asserts/pic1.jpg")} alt="pic1" />
                        <Carousel.Caption>
                            <h3>Title</h3>
                            <p className="d-none d-sm-block">Short Description</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className="d-block w-100" src={require("../asserts/pic2.jpg")} alt="pic2" />
                        <Carousel.Caption>
                            <h3>Title</h3>
                            <p className="d-none d-sm-block">Short Description</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className="d-block w-100" src={require("../asserts/pic3.jpg")} alt="pic3" />
                        <Carousel.Caption>
                            <h3>Title</h3>
                            <p className="d-none d-sm-block">Short Description</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className="d-block w-100" src={require("../asserts/pic4.jpg")} alt="pic4" />
                        <Carousel.Caption>
                            <h3>Title</h3>
                            <p className="d-none d-sm-block">Short Description</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className="d-block w-100" src={require("../asserts/pic5.jpg")} alt="pic5" />
                        <Carousel.Caption>
                            <h3>Title</h3>
                            <p className="d-none d-sm-block">Short Description</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className="d-block w-100" src={require("../asserts/pic3.jpg")} alt="pic3" />
                        <Carousel.Caption>
                            <h3>Title</h3>
                            <p className="d-none d-sm-block">Short Description</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className="d-block w-100" src={require("../asserts/pic6.jpg")} alt="pic6" />
                        <Carousel.Caption>
                            <h3>Title</h3>
                            <p className="d-none d-sm-block">Short Description</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </Col>
        </Row>
    );
}

export default Banner;
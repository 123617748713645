import "./App.css";
import MainRoutes from "./components/MainRoutes";
import NavBarMenu from "./components/NavBarMenu";
import cms from './cms/CMS.json';
import { useEffect } from "react";

function App() {

  useEffect(() => {
    document.title = props.browserTabTitle;    
  }, []);

  const getCommonContent = () => {
    return cms && cms.filter(x=> x.key === "common")[0];
  };

  let props = getCommonContent();

  return (
    <div className="bg-light" >
      <div className="container d-flex justify-content-between text-primary">
        <span className="text-start"><i className="bi bi-envelope"></i>{props.topEmailID}</span>
        <span>{props.topDescription}</span>
        <span className="text-end"><i className="bi bi-whatsapp"></i>{props.topWhatsAppPhoneNo}</span>
      </div>
      <NavBarMenu />
      <br />
      <MainRoutes />
      <footer>
        <p>&copy; 2023 Doodled Cookie Co</p>
      </footer>
    </div>
  )
}



export default App;

import { Col, Row } from "react-bootstrap";

function AboutUs() {
    return (<section id="aboutus" className="bg-light">
        <h3 className="text-primary my-3 py-5 text-center">Our Sweet Story</h3>
        <div>
            <Row>
                <Col md={7} lg={7}> <div className="text-center">
                    <h3>Doodled Cookie Co - Couture Cookies by G & J</h3>                    
                    <h4>Background:</h4>
                    <p>Geetha Jayendran, a B.tech Fashion technology graduate from Kumara Gu College of Technology, Coimbatore, brings a rich background of five years in apparel merchandising in liaison/buying offices in Tirupur. After embracing motherhood and facing increased responsibilities, Geetha found inspiration in an unexpected place - "DIANA AND ROMA," a YouTube channel showcasing cookie decoration. This spark led to the creation of Doodled Cookie Co, introducing Royal icing cookies to India.</p>
                    <h4>The Story of DCC:</h4>
                    <p>Being the primary caregiver to her son while her husband worked long hours in Tirupur, Geetha sought a creative outlet. Watching cookie decorating videos ignited an idea. In a country captivated by cakes, she envisioned a niche for beautifully designed and crafted cookies. With a passion for design, color, and texture, Geetha learned the art of making cookies from a chef, and Doodled Cookie Co was born. The venture is a joint effort with her supportive husband, Jayendran, who handles external aspects of the business, including accessories and packaging.</p>

                </div></Col>
                <Col md={4} lg={4}> 
                <h4>Founder: Geetha Jayendran</h4>
                <img className="d-block w-100" src={require("../asserts/CompanyName.webp")} alt="pic1"></img> 
                </Col>
            </Row>
        </div>
    </section>);
}

export default AboutUs;
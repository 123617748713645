import React from "react";
import Navbar from 'react-bootstrap/Navbar';
import { Container, Nav } from "react-bootstrap";

import logo from '../asserts/logoIcon.ico';
import ShoppingCart from "./ShoppingCart";

const NavBarMenu = () => {
  return (
    <Navbar expand="lg" className="bg-body-tertiary" style={{backgroundColor: "#071740", position: "sticky"}} fixed="top">
      <Container>
        <Navbar.Brand href="#home"><h2><span><i className="bi bi-cookie"></i></span>Doodled Cookie Co</h2></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav>
            <Nav.Link href="/" className="fw-bold">Home</Nav.Link>
            {/* <Nav.Link href="/#products" className="fw-bold">Cookies</Nav.Link> */}
            {/* <Nav.Link href="/#blog" className="fw-bold">Blog</Nav.Link>           */}
            <Nav.Link href="/#Photos" className="fw-bold">Gallery</Nav.Link>  
            <Nav.Link href="/#aboutus" className="fw-bold">About Us</Nav.Link>            
            <Nav.Link href="#contact" className="fw-bold">Contact</Nav.Link>
            {/* <ShoppingCart /> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
export default NavBarMenu;
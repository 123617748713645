import { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

function Admin(props) {

    const [cmsData, setCMSData] = useState(props.cmsData);

    const onImageSelected = (img) => {
        console.log(img);
    }

    const setAttr = (key, value) => {

    }

    const getIteratingJSON = (json) => {
        let _data = [];
        Object.keys(json).forEach(function (key) {
            if (key === "content") {
                const _subContents = json[key];
                let arrItems = [];
                _subContents && _subContents.length > 0 && _subContents.map((_subItem) => {
                    const _res = getIteratingJSON(_subItem);
                    arrItems.push({ name: _subItem.name, content: [..._res] });
                })

                _data.push({ name: key, content: [...arrItems] });
            }
            else {
                _data.push({ name: key, value: json[key] });
            }
        });

        return _data;
    }

    const renderFields = (itm) => {
        return <p key={itm.name}>{itm.name} : <input key={itm.name} value={itm.value} onChange={(evt) => setAttr(itm.name, evt)} /></p>
    }

    const renderContent = (item) => {
        return item && <>
            <h4>{item.name}</h4>
            {
                 item && item.content && item.content.length > 0 ? item.content.map((itm) => {
                    return <>
                        <h4>{itm.name}</h4>
                        {renderContent(itm.content)}
                    </>
                }) : renderFields(item)
            }

        </>
    }

    const renderSection = (section) => {
        const cmsData = getIteratingJSON(section);

        return cmsData && cmsData.length > 0 && cmsData.map((itm) => {
            if (itm.name === "content") {
                return itm && itm.content && itm.content.length > 0 && itm.content.map((i) => {
                    return renderContent(i);

                    // return <h4>{i.name}</h4>
                    // if (i.content) {
                    //     return i && i.content && i.content.length > 0 && i.content.map((_i) => {
                    //         return <> <h4>{i.name}</h4>
                    //             {renderFields(_i)}
                    //         </>
                    //     });
                    // }
                    // else {
                    //     return renderFields(i);
                    // }


                })
            }
            else {
                return renderFields(itm);
            }
        })
    }

    return (<div className="bg-light">
        <Container>
            <div className="text-center">
                <h2>Admin Screen</h2>
                <div className="mb-1">
                    <input
                        type="file"
                        name="myImage"
                        onChange={(event) => onImageSelected(event.target.files[0])}
                    />
                </div>
                <Tabs className="mb-3" fill >
                    {cmsData && cmsData.length > 0 && cmsData.map((item) => {
                        return <Tab key={item.name} eventKey={item.name} title={item.name}>
                            <h4>{item.name} Section </h4>
                            {renderSection(item)}
                        </Tab>
                    })
                    }

                </Tabs>
            </div>
        </Container>
    </div>);
}

export default Admin;
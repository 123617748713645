import React, { useState } from "react";
import { Button, Offcanvas } from "react-bootstrap";
import CartItems from "./CartItems";


function ShoppingCart() {
    const [show, setShow] = useState(false);
    return (
        <>
            <Button variant="light" onClick={() => setShow(!show)} className="me-2 position-relative">
                <i className="bi bi-cart4"></i>
                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                    7
                    <span className="visually-hidden">Shopping Cart</span>
                </span>
            </Button>
            <Offcanvas show={show} onHide={() => setShow(!show)} placement={'end'}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Shopping Cart</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <CartItems />
                    <div className="text-end mt-3">
                        <Button variant="primary">Check Out</Button>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default ShoppingCart;

import React from "react";
import { Col, Row } from "react-bootstrap";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";

function Album() {

    const [index, setIndex] = React.useState(-1);

    const breakpoints = [3840, 2400, 1080, 640, 384, 256, 128, 96, 64, 48];

    const unsplashPhotos = [
        { id: "pic1.jpg", width: 1080, height: 800 },
        { id: "pic2.jpg", width: 1080, height: 1620 },
        { id: "pic3.jpg", width: 1080, height: 720 },
        { id: "pic4.jpg", width: 1080, height: 721 },
        { id: "pic5.jpg", width: 1080, height: 1620 },
        { id: "pic6.jpg", width: 1080, height: 607 },
        { id: "pic7.jpg", width: 1080, height: 608 },
        { id: "pic8.jpg", width: 1080, height: 720 },
        { id: "pic9.jpg", width: 1080, height: 1549 }];

    const unsplashLink = (id, width, height) =>
        require(`../asserts/${id}`);

    const photos = unsplashPhotos.map((photo) => {
        const width = breakpoints[0];
        const height = (photo.height / photo.width) * width;

        return {
            src: unsplashLink(photo.id, width, height),
            width,
            height,
            srcSet: breakpoints.map((breakpoint) => {
                const height = Math.round((photo.height / photo.width) * breakpoint);
                return {
                    src: unsplashLink(photo.id, breakpoint, height),
                    width: breakpoint,
                    height,
                };
            }),
        };
    });

    return (<section id="Photos" className="bg-light">
        <h3 className="text-primary my-3 py-5 text-center">Albums</h3>
        <div>
            <Row>
                <PhotoAlbum photos={photos} layout="rows" targetRowHeight={150}  onClick={({ index: current }) => setIndex(current)}/>
                <Lightbox
                    index={index}
                    slides={photos}
                    open={index >= 0}
                    close={() => setIndex(-1)}
                />
            </Row>
        </div>
    </section>);
}

export default Album;